<template>
  <div class="user-container">
    <div class="header">
      <div class="avatar">
        <img
          v-if="userInfo.avatar && userInfo.avatar !== '/avatar/default.png'"
          :src="BASE_IMG + userInfo.avatar"
          alt=""
        />
        <div v-else class="avatar-text">
          {{ userInfo.name?.substr(0, 1).toUpperCase() }}
        </div>
      </div>
      <div>{{ userInfo.name }}</div>
    </div>
    <div class="my-order">
      <div class="title">{{ $t("我的訂單") }}</div>
      <div class="view-order" @click="getOrderList('-1')">
        {{ $t("產看全部訂單") }}
        <img src="@/assets/icons/arrow-right.png" alt="" />
      </div>
    </div>
    <div class="order-status">
      <div class="topay" @click="getOrderList(0)">
        <img src="@/assets/icons/topay.png" alt="" />
        <div>{{ $t("待付款") }}</div>
      </div>
      <div class="toDeliver" @click="getOrderList(2)">
        <img src="@/assets/icons/toDeliver.png" alt="" />
        <div>{{ $t("待發貨") }}</div>
      </div>
      <div class="toReceive" @click="getOrderList(3)">
        <img src="@/assets/icons/toReceive.png" alt="" />
        <div>{{ $t("待收貨") }}</div>
      </div>
      <div class="complete" @click="getOrderList(4)">
        <img src="@/assets/icons/complete.png" alt="" />
        <div>{{ $t("已完成") }}</div>
      </div>
    </div>
    <div class="user-content">
      <div class="user-item" @click="gotoInvitation">
        <div class="left">
          <img src="@/assets/icons/Invitation.png" alt="" />
          <div>{{ $t("邀請碼") }}</div>
        </div>
        <img src="@/assets/icons/arrow-right.png" alt="" />
      </div>
      <div class="user-item" @click="gotoCoupon">
        <div class="left">
          <img src="@/assets/icons/coupon.png" alt="" />
          <div>{{ $t("優惠券") }}</div>
        </div>
        <img src="@/assets/icons/arrow-right.png" alt="" />
      </div>
      <div class="user-item" @click="gotoAddress">
        <div class="left">
          <img src="@/assets/icons/address.png" alt="" />
          <div>{{ $t("收貨地址") }}</div>
        </div>
        <img src="@/assets/icons/arrow-right.png" alt="" />
      </div>
      <div class="user-item" @click="gotoQa">
        <div class="left">
          <img src="@/assets/icons/qa.png" alt="" />
          <div>{{ $t("常見問題") }}</div>
        </div>
        <img src="@/assets/icons/arrow-right.png" alt="" />
      </div>
      <div class="user-item" @click="gotoPointMall">
        <div class="left">
          <img src="@/assets/icons/points.png" alt="" />
          <div>{{ $t("我的積分") }}</div>
        </div>
        <img src="@/assets/icons/arrow-right.png" alt="" />
      </div>
      <div class="user-item">
        <div class="left">
          <img src="@/assets/icons/setting.png" alt="" />
          <div>{{ $t("语言") }}</div>
        </div>
        <div class="lang" @click="getLangChange">
          <div>{{ lang }}</div>
          <img src="@/assets/icons/arrow-right.png" alt="" />
        </div>
      </div>

      <div class="logout" @click="getLogout">
        <img src="@/assets/icons/logout.png" alt="" />
        <div>{{ $t("登出") }}</div>
      </div>
    </div>
    <selectDialog
      @handleChange="handleChange"
      :defaultValue="defaultValue"
      :isShow="isShow"
      :data="columns"
    ></selectDialog>

    <!-- 提示弹窗 -->
    <van-dialog v-model:show="isCancelPay" :showConfirmButton="false">
      <div class="register-dialog">
        <div class="title">
          <div>{{ $t("提示") }}</div>
          <img
            @click="isCancelPay = false"
            src="@/assets/icons/ic_Close@2x.png"
            alt=""
          />
        </div>
        <div class="register-text">
          <div>{{ $t("確認登出賬號？") }}</div>
        </div>
        <div class="pay-btn">
          <div class="Confirm" @click="handleLogout">
            {{ $t("確認") }}
          </div>
          <div class="Cancel" @click="isCancelPay = false">
            {{ $t("取消") }}
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "@/utils/mixins.js";
import { removeToken } from "@/utils/token.js";
export default {
  mixins: [mixins],
  data() {
    return {
      langEnv: 2,
      lang: "English",
      isShow: false,
      columns: [
        {
          value: "2",
          label: "English"
        },
        {
          value: "3",
          label: "繁體中文"
        }
      ],
      defaultValue: "2",
      isCancelPay: false
    };
  },
  computed: {
    ...mapState("user", ["userInfo"])
  },
  created() {
    this.langEnv = window.localStorage.getItem("langEnv") || 2;
    this.lang =
      window.localStorage.getItem("langEnv") == 2 ? "English" : "繁體中文";
    this.$store.dispatch("user/getUserInfo");
  },
  methods: {
    gotoInvitation() {
      this.$router.push("/shareCode");
    },
    gotoCoupon() {
      this.$router.push("/myCoupon");
    },
    handleLogout() {
      removeToken();
      this.$router.push("/login");
    },
    getLogout() {
      this.isCancelPay = true;
    },
    handleChange(item) {
      this.isShow = false;
      window.localStorage.setItem("langEnv", item.value);
      window.location.reload();
      this.lang = item.label;
    },

    getLangChange() {
      this.isShow = true;
      this.defaultValue = this.langEnv;
    },
    gotoQa() {
      this.$router.push(`/question?langEnv=${this.langEnv}`);
    },
    gotoPointMall() {
      this.$router.push(`/pointsMall/pointsRecord`);
    },
    getOrderList(type) {
      if (type >= 0) {
        this.$router.push(`/user/orderList?status=${type}`);
      } else {
        this.$router.push("/user/orderList");
      }
    },
    gotoAddress() {
      this.$router.push("/user/address");
    }
  }
};
</script>

<style lang="scss" scoped>
.user-container {
  min-height: 100vh;
  background-color: #fff;
  .header {
    background: url("~@/assets/image/user/userbg.png") no-repeat;
    background-size: cover;
    padding: 32px 16px;
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  .order-status {
    display: flex;
    padding: 16px 0px;
    div {
      flex: 1;
      text-align: center;
    }
    img {
      width: 32px;
      height: 32px;
      margin: auto;
    }
  }
  .my-order {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    background-color: #fff;

    .title {
      font-size: 18px;
      color: #1a1a1a;
      font-weight: 500;
    }
    .view-order {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #999999;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .avatar {
    width: 80px;
    height: 80px;
    background: #90d12e;
    border-radius: 40px 40px 40px 40px;
    overflow: hidden;
    margin-right: 8px;
    .avatar-text {
      text-align: center;
      line-height: 80px;
      font-size: 32px;
      font-weight: 700;
      color: #fff;
    }
  }
}
.register-dialog {
  padding: 24px;
  .register-text {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #1a1a1a;
    text-align: center;
    span {
      color: #3491fa;
    }
  }
  .pay-btn {
    .Cancel,
    .Confirm {
      flex: 1;
      text-align: center;
      margin-top: 10px;
    }
    .Cancel {
      background: #eeeeee;
      border-radius: 12px 12px 12px 12px;
      padding: 8px;
    }
    .Confirm {
      font-size: 16px;
      color: #ffffff;
      background-color: #90d12e;
      border-radius: 12px 12px 12px 12px;
      padding: 8px;
    }
  }
  .title {
    text-align: center;
    margin-bottom: 12px;
    font-weight: 500;
    div {
      text-align: center;
    }

    img {
      width: 24px;
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}
.user-content {
  background-color: #fff;
  padding: 0 20px;
  .logout {
    display: flex;
    align-items: center;
    padding: 16px 32px;
    color: #999999;
    font-size: 16px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
  .user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 18px 0;
    img {
      width: 16px;
      height: 16px;
    }
    .left {
      display: flex;
      align-items: center;
      white-space: nowrap;
      img {
        height: 32px;
        width: 32px;
      }
    }
  }
  .lang {
    display: flex;
    align-items: center;
    color: #999999;
  }
}
</style>
